
@font-face {
    font-family: 'Visby Round CF';
    src: url('./fonts/visbyround_light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Visby Round CF';
    src: url('./fonts/visbyround_regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Visby Round CF';
    src: url('./fonts/visbyround_medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Visby Round CF';
    src: url('./fonts/visbyround_demibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Visby Round CF';
    src: url('./fonts/visbyround_bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }